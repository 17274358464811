import { useMutation } from "@tanstack/vue-query";
import { toast } from "~/components/toast";

export const useValidateAppleSignInMutation = () => {
  const router = useRouter();
  const authStore = useAuthStore();
  return useMutation({
    mutationFn: async (data: {
      accessToken: string;
      name: string;
    }) => {
      const response = await useApi().auth["validate-apple"].$post({
        json: data
      });

      return response.json();
    },
    onError: async (error) => {
      toast.error('Houve um erro ao fazer login com Apple. Tente novamente ou contate o suporte.');
    }
  });
};
