<template>
  <UiButton loading-text="Entrando..." :loading="isPending || isSuccess" @click="login" variant="flat" bg-color="black"
    class="!bg-gray-600 rounded-lg h-10 ">
    <div class="flex items-center justify-center gap-2">
      <img src="/images/icons/apple_bw.svg" class="size-5 mb-1" />
      Continuar com Apple
    </div>
  </UiButton>
</template>

<script setup lang="ts">
import { SocialLogin } from '@capgo/capacitor-social-login';
import { UiButton } from '~/components/ui';
import { useValidateAppleSignInMutation } from '../mutations/useValidateAppleSignInMutation';

const { mutateAsync: validateAppleSignIn, isPending, isSuccess } = useValidateAppleSignInMutation();

const router = useRouter();
const { token, setToken } = useToken();
const isApp = useIsApp();

onMounted(async () => {
  if (isApp) {
    await SocialLogin.initialize({
      apple: {
        clientId: 'com.grqtech.respostacerta'
      },
    });
  }
})

const loginFromWeb = async () => {
  
}

const login = async () => {
  if (!isApp) {
    loginFromWeb();
    return;
  }

  try {
    const res = await SocialLogin.login({
      provider: 'apple',
      options: {
        scopes: ['email', 'name'],
      },
    });

    if (!res.result || !res.result.accessToken?.token || !res.result.idToken) {
      return;
    }

    console.log('res', res);

    const data = await validateAppleSignIn({
      accessToken: res.result.accessToken.token,
      name: (res.result.profile.givenName + ' ' + res.result.profile.familyName).trim(),
    })

    console.log('logged in apple', data.session.token);

    await setToken(data.session.token);

    router.push('/inicio');
  } catch (error) {
    console.error(error);
  }
}
</script>